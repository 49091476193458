import { defineStore } from 'pinia';
import type { IClinic } from 'types';

export const useClinicsStore = defineStore({
  id: 'clinics',
  state: () => ({
    clinics: [] as IClinic[],
  }),
  getters: {
    getClinics: state => state.clinics,
  },
  actions: {
    storeClinics(clinicsList: IClinic[]) {
      this.clinics = clinicsList;
    },
  },
});
